import React from 'react';
import {ProductList} from '../../../gallery/components/ProductList/ProductList';
import {IGallerySantaProps, IPropsInjectedByViewerScript, MobileFiltersPanelState} from '../../../types/galleryTypes';
import s from '../../../gallery/components/GalleryApp/GalleryApp.scss';
import CategoriesClasses from './CategoriesApp.scss';
import classNames from 'classnames';
import {Omit} from '@wix/native-components-infra/dist/es/src/types/types';
import autobind from 'auto-bind-es5';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import 'wicg-inert';
import {CategoriesBreadcrumbs} from '../CategoriesBreadcrumbs/CategoriesBreadcrumbs';
import {Hero} from '../Hero/Hero';
import {SideFilters} from '../../../common/components/SideFilters/SideFilters';
import {Sort} from '../../../common/components/Sort/Sort';
import {
  announceIfFilterResultChanged,
  shouldShowGalleryAppInLayout,
} from '../../../gallery/components/GalleryApp/appUtils';
import {ProductsCounter} from '../ProductsCounter/ProductsCounter';
import {MobileFilters} from '../../../common/components/MobileFiltersAndSort/MobileFilters/MobileFilters';
import {EmptyGallery} from '../../../common/components/EmptyGallery/EmptyGallery';
import {AppliedFilters} from '../../../gallery/components/Filters/AppliedFilters/AppliedFilters';
import {ConditionalRender} from '../ConditionalRender/ConditionalRender';
import {CategoryHeaderPosition} from '../../../constants';

export enum DataHook {
  Root = 'category-page-app-root',
  Content = 'category-page-app-content',
  Container = 'category-page-app-container',
  HeroContainer = 'hero-container',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SideFilters = 'category-page-aside-filters',
  MobileContainer = 'mobile-container',
  Breadcrumbs = 'category-page-breadcrumbs',
  BreadcrumbsComponent = 'category-breadcrumbs-component',
  ProductListContainer = 'product-list-container',
  CounterAndSortContainer = 'counter-sort-container',
  AppliedFiltersContainer = 'applied-filters-container',
}

export type IGalleryAppProps = Omit<IPropsInjectedByViewerScript & IGallerySantaProps, IGalleryGlobalProps['globals']> &
  IGalleryGlobalProps &
  IProvidedTranslationProps;

class CategoriesAppComp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;

  constructor(props) {
    super(props);
    this.state = {
      isSSR: props.isSSR,
    };
    autobind(this);
  }

  public componentDidMount() {
    this.scrollToProductIfReturnedFromProductPage();
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    announceIfFilterResultChanged(prevProps, this.props, () =>
      this.a11yAnnouncer.announce(
        this.props.t('announceFiltersUpdate', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      )
    );
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private readonly updateStateForScrollRestorationAndClearQueryParams = () => {
    // Thunderbolt's way to remove query params calls `locationSdkProvider.ts` which calls `history.replaceState` with `null`.
    // `scrollRestorationApi.ts` happens on loading of page and reads `history.state` which now is `null`. So it's logic is to scroll to top.
    // Fix: remove the query params natively, and call `history.replaceState` with the correct `scrollY` position.
    const urlObj = new URL(window.location.href);
    urlObj.searchParams.delete('scrollToProduct');
    window.history.replaceState({scrollY: window.scrollY}, '', urlObj.toString());
  };

  private scrollToProductIfReturnedFromProductPage() {
    const {
      globals: {
        scrollToProduct,
        clearScrollToProduct,
        experiments: {shouldScrollToProductPositionWhenReturningFromProductPageFixEnabled},
      },
    } = this.props;

    if (scrollToProduct) {
      if (!shouldScrollToProductPositionWhenReturningFromProductPageFixEnabled) {
        clearScrollToProduct();
      }
      const productItem = document.querySelector(`[data-hook="${DataHook.Root}"] [data-slug="${scrollToProduct}"]`);
      if (productItem) {
        if (shouldScrollToProductPositionWhenReturningFromProductPageFixEnabled) {
          productItem.scrollIntoView({block: 'center'});
          this.updateStateForScrollRestorationAndClearQueryParams();
        } else {
          history.replaceState({scrollY: 0}, '');
          setTimeout(() => productItem.scrollIntoView({block: 'center'}), 0);
        }
      }
    }
  }

  private reportAppLoaded() {
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  private emptyCategoryPage(classnames, responsive) {
    const {textsMap} = this.props.globals;
    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div className={CategoriesClasses.layoutContainer}>
          <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
            <div data-hook={DataHook.Content} className={classnames.content}>
              <div data-hook={DataHook.Container} className={CategoriesClasses.container}>
                <EmptyGallery
                  localeMap={{
                    emptyCategoryPageEditorTitle: textsMap.emptyCategoryPageEditorTitle,
                    emptyCategoryPageEditorSubtitle: textsMap.emptyCategoryPageEditorSubtitle,
                  }}
                  isEditorCategoryPageMode={true}
                  hasFilters={false}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public render() {
    const {styles, stylesParams, currentCategory, numberOfSelectedFilterTypes, shouldShowSort} = this.props.globals;
    const responsive = styles.get(stylesParams.responsive);
    const {shouldShowMobile, mobileFiltersPanelState} = this.props.globals;
    const isDesktop = !shouldShowMobile;
    const isHeroFullStretched =
      isDesktop && styles.get(stylesParams.gallery_categoryHeaderPosition) === CategoryHeaderPosition.StretchedTop;
    const {hasSelectedFilters} = this.props.globals;

    if (!shouldShowGalleryAppInLayout(this.props.isLoaded, this.props.globals)) {
      return null;
    }

    const shouldRenderSideFilters =
      isDesktop && (styles.get(stylesParams.galleryShowFilters) || styles.get(stylesParams.gallery_showCategories));

    const shouldRenderDesktopSort = isDesktop && shouldShowSort;

    const shouldShowAppliedFilters = numberOfSelectedFilterTypes > 0;

    const classnames = {
      app: classNames([s.galleryApp, CategoriesClasses.rightColumn], {
        deviceMobile: shouldShowMobile,
        notCssPerBreakpoint: !this.props.host.usesCssPerBreakpoint,
        [s.galleryContentWidth]: shouldRenderSideFilters,
      }),
      filterContainer: classNames({
        [s.filtersContainerWidth]: shouldRenderSideFilters,
      }),
      columnsContainer: classNames(CategoriesClasses.columnsContainer, s.responsiveWidth),
      content: classNames(s.content, s.fullWidth, {
        [s.contentResponsive]: responsive,
      }),
      appliedFiltersContainer: CategoriesClasses.appliedFiltersContainer,
      breadcrumbsContainer: classNames({
        [CategoriesClasses.breadcrumbsContainer]: !shouldShowMobile,
        [CategoriesClasses.breadcrumbsContainerMobile]: shouldShowMobile,
      }),
      stretchedHeroContainer: classNames(CategoriesClasses.heroContainer, CategoriesClasses.fullStretched),
    };

    if (!currentCategory) {
      return this.emptyCategoryPage(classnames, responsive);
    }

    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div className={CategoriesClasses.layoutContainer}>
          <ConditionalRender by={'gallery_showCategoriesBreadcrumbs'}>
            <div className={classnames.breadcrumbsContainer}>
              <CategoriesBreadcrumbs />
            </div>
          </ConditionalRender>
          {isHeroFullStretched && (
            <ConditionalRender
              by={'showHeroSection'}
              className={classnames.stretchedHeroContainer}
              data-hook={`${DataHook.HeroContainer}-${CategoryHeaderPosition.StretchedTop}`}>
              <Hero />
            </ConditionalRender>
          )}
          <div className={classnames.columnsContainer}>
            {shouldRenderSideFilters && (
              <div className={classnames.filterContainer}>
                <SideFilters {...this.props.globals} shouldStretchVertically={false} />
              </div>
            )}
            <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
              <div data-hook={DataHook.Content} className={classnames.content}>
                <div data-hook={DataHook.Container} className={CategoriesClasses.container}>
                  {!isHeroFullStretched && (
                    <ConditionalRender
                      by={'showHeroSection'}
                      className={CategoriesClasses.heroContainer}
                      data-hook={`${DataHook.HeroContainer}-${CategoryHeaderPosition.MinimizedAboveGallery}`}>
                      <Hero />
                    </ConditionalRender>
                  )}
                  <div data-hook={DataHook.ProductListContainer}>
                    {shouldShowAppliedFilters && (
                      <ConditionalRender by={'gallery_showAppliedFilters'}>
                        <div
                          data-hook={DataHook.AppliedFiltersContainer}
                          className={classnames.appliedFiltersContainer}
                          role="group"
                          aria-label={this.props.t('appliedFiltersContainerSR')}>
                          <AppliedFilters />
                        </div>
                      </ConditionalRender>
                    )}
                    <div
                      data-hook={DataHook.CounterAndSortContainer}
                      className={CategoriesClasses.countAndFiltersContainer}>
                      <ConditionalRender by={'gallery_showCategoriesProductsCounter'}>
                        <ProductsCounter />
                      </ConditionalRender>
                      {shouldRenderDesktopSort && <Sort isFloatingView={true} />}
                      {shouldShowMobile && mobileFiltersPanelState !== MobileFiltersPanelState.NONE && (
                        <MobileFilters combineFiltersAndSort={true} isSplitView={true} />
                      )}
                    </div>
                    <ProductList hasFilters={hasSelectedFilters} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const CategoriesApp = withGlobals(withTranslations()(CategoriesAppComp));
